import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { ValidatorForm } from 'react-form-validator-core';
import _ from "lodash"
class TextValidator extends ValidatorComponent {

    render() {

        ValidatorForm.addValidationRule('isAddressZipcodeMatch', (value) => {
            if(!_.isEmpty(value)){
                console.log("value-->",value)
               var zipCodePattern = /^\d{5}$|^\d{5}-\d{1,4}$/;
              
                return zipCodePattern.test(value);
            }
            return true;
          });

        const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

        return (
            <div>
                <input
                    {...rest}
                    ref={(r) => { this.input = r; }}
                />
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'red' }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default TextValidator;