import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
var attorneyapi = {

    getAttorneys: async function (propertyID, pageNo) {
        let url = config.baseUrl + '/api/v1/attorneys?property_id=' + propertyID;
        if (pageNo) {
            url = url + "?page=" + pageNo;
        }
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        return await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getAttorneys' + response)
            }).catch(function (error) {

            })
    },
    createAttorney: async function (attorney, propertyID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/attorneys?property_id=' + propertyID;
        let form = new FormData();
        for (var key in attorney) {
            if (!Array.isArray(attorney[key])) {
                form.append("attorney[" + key + "]", attorney[key]);
            }
        }
        let init = {
            method: "POST",
            headers: await helpers.getSavedToken(),
            body: form
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.clone().json();
                }
                throw new Error('Something went wrong, while attorney create' + response)
            })
            .then(responseJson => {

                res = responseJson;
            }).catch(function (error) {

            })
        return res;
    },
    updateAttorney: async function (attorney, propertyID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/attorneys/' + attorney.id + '?property_id=' + propertyID;
        let form = new FormData();
        for (var key in attorney) {
            if (!Array.isArray(attorney[key])) {
                form.append("attorney[" + key + "]", attorney[key]);
            }
        }
        let init = {
            method: "PUT",
            headers: await helpers.getSavedToken(),
            body: form
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.clone().json();
                }
                throw new Error('Something went wrong, while attoney Update' + response)
            })
            .then(responseJson => {

                res = responseJson;
            }).catch(function (error) {

            })
        return res;
    },
    deleteAttorney: async function (attorneyID, propertyID) {
        var res = '';
        let url = config.baseUrl + '/api/v1/attorneys/' + attorneyID + "?property_id=" + propertyID;
        var init = {
            method: "DELETE",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while attorney delete' + response)
            }).then(a => {
                res = a;
            }).catch(function (error) {

            })
        return res;
    },
}
export default withRouter(attorneyapi);