import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
var lienapi = {
    lienCreate: async function (lien, propertyID, actionID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/liens?property_id='+ propertyID;
        let init = {
            method: "POST",
            headers: await helpers.defaultHeaders(),
            body: JSON.stringify(lien)
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                }
                throw new Error('Something went wrong, while lien Create' + response)
            }).then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;

    },
    lienFetch: async function (lien, propertyID, actionID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/liens/' + actionID + '?property_id=' + propertyID;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while lien Fetch' + response)
            }).then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;
    },
    lienUpdate: async function (lien, propertyID, actionID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/liens/' + actionID + '?property_id=' + propertyID;
        let form = new FormData();
        for (var key in lien) {
            if (!Array.isArray(lien[key])) {
                form.append("lien["+key+"]", lien[key]);
            }       
        }
        let init = {
            method: "PUT",
            headers: await helpers.getSavedToken(),
            body: form
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.clone().json();
                }
                throw new Error('Something went wrong, while lien Update' + response)
            })
            .then(responseJson => {

                res = responseJson;
            }).catch(function (error) {

            })
        return res;
    },
}
export default withRouter(lienapi);