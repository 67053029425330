import FormComp from "../crud/FormComp";
import React from 'react';
import { FormGroup, Label, CustomInput, Input } from 'reactstrap';
import TextValidator from '../helper_components/TextValidator'
import { ValidatorForm } from 'react-form-validator-core';
import apicall from '../../utils/apicall'
import helpers from '../../utils/helpers'
import config from "../../utils/config";
import TabsComponent from '../helper_components/TabsComponent'
export default class AttorneyModal extends FormComp {

    constructor(props) {
        super(props);
        this.state = {
            row: props.row,
            title: props.title,
            propertyid: props.propertyid
        }
        this.state = {
            ...this.state,
            ...this.props
        };
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {

            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }

    handleSubmit = (event) => {
        var row = this.state.row;
        if (row.id) {
            apicall.updateAttorney(row, this.state.propertyid).then(
                response => {
                    if (response) {
                        helpers.notify("Updated successfully.");
                        this.toggleModal();
                    }
                }
            );
        } else {
            apicall.createAttorney(row, this.state.propertyid).then(
                response => {
                    if (response) {
                        helpers.notify("Added successfully.");
                        this.toggleModal();
                    }
                }
            );
        }
        event.preventDefault();
    }


    formElements = () => {
        var listofTabs = ["Attorney", "Documents"];
        let tabsComponentsList = new Array(listofTabs.length).fill(null);
        tabsComponentsList[0] = this.attorneyForm();
        tabsComponentsList[1] = this.documentComp();
        return (
            <>
                <TabsComponent theme="modalTabs" components={tabsComponentsList}
                    listofTabs={listofTabs} generateTabURL={false} />

            </>
        )
    }


    attorneyForm = () => {
        let attorneyTypes = [];
        attorneyTypes.push(<option value="" key="defaultValue">Select Attorney Type</option>)
        for (let attorneyType in config.attorneyTypes) {
            attorneyTypes.push(<option value={attorneyType} key={attorneyType}>{config.attorneyTypes[attorneyType]}</option>)
        }

        ValidatorForm.addValidationRule('isZipcodeMatch', (value) => {
            var zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
            return zipCodePattern.test(value);
        });
        return (
            <>
                <FormGroup>
                    <Label for="">Attorney Name *</Label>
                    <TextValidator type="text" name="name" id="name" placeholder="Enter name" onChange={this.handleChange} validators={['required', 'maxStringLength: 50']}
                        errorMessages={['Name is required', 'Should not be exceed 50 characters']} value={(this.state.row.name) ? this.state.row.name : ''} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Attorney Information *</Label>
                    <TextValidator type="text" name="information" id="information"
                        placeholder="Attorney Information" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Information is required']}
                        value={(this.state.row.information) ?
                            this.state.row.information : ''} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Phone Number *</Label>
                    <TextValidator type="text" name="contact_phone" id="contact_phone"
                        placeholder="Attorney Phone" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Phone Number is required']}
                        value={(this.state.row.contact_phone) ?
                            this.state.row.contact_phone : ''} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Email *</Label>
                    <TextValidator type="text" name="contact_email" id="contact_email"
                        placeholder="Email" onChange={this.handleChange}
                        validators={['required', 'isEmail']} errorMessages={['Email is required', 'Email is not valid']}
                        value={(this.state.row.contact_email) ?
                            this.state.row.contact_email : ''} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Fax</Label>
                    <TextValidator type="text" name="contact_fax" id="contact_fax"
                        placeholder="Attorney Fax" onChange={this.handleChange}
                        validators={[]} errorMessages={['Fax is required']}
                        value={(this.state.row.contact_fax) ?
                            this.state.row.contact_fax : ''} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Website</Label>
                    <TextValidator type="text" name="website" id="website"
                        placeholder="Attorney Website" onChange={this.handleChange}
                        validators={[]} errorMessages={['Website is required']}
                        value={(this.state.row.website) ?
                            this.state.row.website : ''} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Attorney Type</Label>
                    <Input type="select" name="type" id="type" value={this.state.row.type} onChange={this.handleChange} 
                    required disabled={this.state.row.id ? true : false}>
                        {attorneyTypes}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="">Mailing Contact name *</Label>
                    <TextValidator type="text" name="mailing_contact_name" id="mailing_contact_name"
                        placeholder="Mailing Contact name*" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Mailing Contact name is required']}
                        value={(this.state.row.mailing_contact_name) ?
                            this.state.row.mailing_contact_name : ''} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Mailing Street Name *</Label>
                    <TextValidator type="text" name="mailing_street_name" id="mailing_street_name"
                        placeholder="Mailing Street Name" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Mailing Street Name is required']}
                        value={(this.state.row.mailing_street_name) ?
                            this.state.row.mailing_street_name : ''} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Mailing City *</Label>
                    <TextValidator type="text" name="mailing_city" id="mailing_city"
                        placeholder="Mailing City" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Mailing City is required']}
                        value={(this.state.row.mailing_city) ?
                            this.state.row.mailing_city : ''} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Mailing State *</Label>
                    <TextValidator type="text" name="mailing_state" id="mailing_state"
                        placeholder="Mailing State" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Mailing State is required']}
                        value={(this.state.row.mailing_state) ?
                            this.state.row.mailing_state : ''} />
                </FormGroup>
                <FormGroup>
                    <Label for=""> Mailing Zip *</Label>
                    <TextValidator type="text" name="mailing_zip" id="mailing_zip" placeholder="XXXXX-XXXX" onChange={this.handleChange}
                        validators={['required', 'isZipcodeMatch']}
                        errorMessages={['Zip is required', 'Invalid Zip Code']}
                        value={(this.state.row.mailing_zip) ?
                            this.state.row.mailing_zip : ''} />
                </FormGroup>

                <FormGroup >
                    <div className="addMarginBottom">Attorney Status</div>
                    <FormGroup style={{ display: 'flex' }}>
                        <CustomInput id="statusActive" type="radio" name="status" value='active'
                            checked={this.state.row.status === 'active' ? 'checked' : ''} onClick={this.handleRadioButton} />
                        <Label>Active</Label>
                        <CustomInput id="statusInactive" type="radio" name="status" value='inactive'
                            checked={this.state.row.status === 'inactive' ? 'checked' : ''} onClick={this.handleRadioButton} />
                        <Label>Inactive</Label>
                    </FormGroup>
                </FormGroup>
            </>
        )
    }
}
