import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const WarningModal = props => {
  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        className="warning-modal"
      >
        <ModalHeader toggle={props.toggle}>Warning</ModalHeader>
        <ModalBody>
          <p>
            Click on "YES" to proceed creating FY assessment payment action.
          </p>
          <p>Click on "NO" to revert Changes</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.onYesClick}>
            Yes
          </Button>{" "}
          <Button color="secondary" onClick={props.onNoClick}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WarningModal;
