import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import config from "../../utils/config";

class PrivateRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            isChecked: false
        }
    }
    componentDidMount() {
        this.checkAuthenticate().then(isAuthenticated => {

        })
    }
    async checkAuthenticate() {
        let isAuthenticated = false;
        await config.localforage.getItem(config.accessToken).then(value => {
            if (typeof value !== "undefined" && value !== null) {
                this.setState({
                    isAuthenticated: true,
                    isChecked: true
                })
            }
            else {
                this.setState({
                    isAuthenticated: false,
                    isChecked: true
                })
            }
        });
        return isAuthenticated
    }

    render() {
        var component = null;
        if (this.state.isAuthenticated && this.state.isChecked) {
            component = <Component {...this.props} />
        }
        else if (this.state.isChecked) {
            window.location = "/";
        }
        return (
            <Route
                {...this.props}
                render={() => component}
            />)
    }
}
export default withRouter(PrivateRoute);
