import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
var inspectionapi={
    inspectionUpdate: async function (inspection, propertyID, actionID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/inspections/' + actionID + '?property_id=' + propertyID;
        let form = new FormData();
        for (var key in inspection) {
            if (!Array.isArray(inspection[key])) {
                form.append("inspection[" + key + "]", inspection[key]);
            }
        }
        let init = {
            method: "PUT",
            headers: await helpers.getSavedToken(),
            body: form
        };
        await fetch(url, init)
            .then(response => {

                if (response.ok) {
                    return response.clone().json()
                }
                throw new Error('Something went wrong, while lien Update' + response)
            })
            .then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;
    },
    inspectionFetch: async function (inspection, propertyID, actionID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/inspections/' + actionID + '?property_id=' + propertyID;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while inspection Fetch' + response)
            }).then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;
    }
}
export default withRouter(inspectionapi);