import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
import _ from 'lodash';
var reportsapi = {
    downloadReports: async function (key,startDate,endDate) {
        let url = config.baseUrl + '/api/v1/reports/' + _.snakeCase(key) ;
        if(startDate){
            url = url + `?start_date=${startDate}`;
        }
        if(endDate){
            url = url + `&end_date=${endDate}`;
        }
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        return await fetch(url, init)
            .then((response) => helpers.blobHandler(response,key))
    },
}
export default withRouter(reportsapi);
