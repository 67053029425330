import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
var tenantmoveinapi = {
    getTenantMoveInList: async function (propertyId) {
        let url = config.baseUrl + '/api/v1/tenant_move_ins?property_id=' + propertyId;
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        return await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error('Something went wrong, while getting move in info' + response)
            }).catch(function (error) {

            })
    },
    createTenantMovein: async function (moveinInfo, propertyid) {
        let url = config.baseUrl + '/api/v1/tenant_move_ins?property_id=' + propertyid
        let form = new FormData();
        for (var key in moveinInfo) {
            if (!Array.isArray(moveinInfo[key])) {
                form.append("tenant_move_in["+key+"]", moveinInfo[key]);
            }
        }
        var init = {
            method: "POST",
            headers: await helpers.getSavedToken(),
            body: form
        };
        return await fetch(url, init)
            .then(response => {
                return response.json();
            }).catch(function (error) {

            })
    },
    updateTenantMovein: async function (moveinInfo, propertyid) {
        let url = config.baseUrl + '/api/v1/tenant_move_ins/' + moveinInfo.id + '?property_id=' + propertyid;
        let form = new FormData();
        for (var key in moveinInfo) {
            if (!Array.isArray(moveinInfo[key])) {
                form.append("tenant_move_in["+key+"]", moveinInfo[key]);
            }
        }
        var init = {
            method: "PUT",
            headers: await helpers.getSavedToken(),
            body: form
        };

        return await fetch(url, init)
            .then(response => {
                return response.json();
            })
            .catch(function (error) {

            })
    },
    deleteTenantMoveIn: async function (id, propertyid) {
        let url = config.baseUrl + '/api/v1/tenant_move_ins/' + id + '?property_id=' + propertyid;
        var init = {
            method: "DELETE",
            headers: await helpers.defaultHeaders()
        };
        return await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while deleting lease info' + response)
            }).catch(function (error) {

            })
    }
}
export default withRouter(tenantmoveinapi);