import React, { Component } from "react";
import { Animated } from "react-animated-css";
import { FormGroup, Label } from 'reactstrap';
import TextValidator from './TextValidator';
export default class FieldHelperComponent extends Component {

    render() {
        if ((typeof this.props.showFields === "undefined") ||
            !this.props.showFields || this.props.showFields === "false") {
            return null;
        }
        var fieldsToRender = this.props.fieldNames.map((fieldName, index) => {
            let validatorsArray = ['required'];
            if(this.props.fieldTypes[index].trim() === "number"){
                validatorsArray.push('minNumber:0');
            }
            return (
                <FormGroup key={this.props.fieldLabels[index] + index}>
                    <Animated  animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
                        <Label for="">{this.props.fieldLabels[index]}</Label>
                        <TextValidator type={this.props.fieldTypes[index]} name={fieldName} id={fieldName}
                            placeholder={this.props.fieldLabels[index]} onChange={this.props.handleInputChange}
                            validators={validatorsArray} errorMessages={[this.props.fieldLabels[index] + ' is required', 'Negative values not allowed']}
                            value={(this.props.formMap[fieldName]) ?
                                this.props.formMap[fieldName] : ''} />
                    </Animated>
                </FormGroup>
            )
        })
        return fieldsToRender
    }
}